import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const TheContainerClient = () => import('@/containers/TheContainerClient')

// Views
const Dashboard = () => import('@/views/Dashboard')

/** LOGIN */
const Login = ()=> import('@/pages/Login')
const NotFound = ()=> import('@/pages/Page404')
const Consultas = () => import('@/pages/Consultas')

/** PROFILE */
const Profile = ()=> import('@/views/profile/index.vue')

/** ACCESS */
const Permission = () => import('@/views/access/permission.vue')
const Roles = () => import('@/views/access/roles.vue')
const Users = () => import('@/views/access/users.vue')
// const AssignedPermission = () => import('@/views/access/assignedpermission.vue')



Vue.use(Router)

const routes = [
  {
    path: '/consultas',
    redirect: '/consultas/clientes',
    name: '',
    component: TheContainerClient,
    children: [
      {
        path: 'clientes',
        name: 'clientes',
        component: Consultas
      },
    ]
  },
  {
    path: '/',
    redirect: '/login',
    name: '',
    component: {
      render (c) { return c('router-view') } //componente 
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
    ]
  },
  {
    path: '/perfil',
    redirect: '/perfil',
    name: 'Mi perfil',
    component: TheContainer, // montar componente
    children: [
      {
        path: '/',
        name: 'Mis datos',
        component: Profile
      },      
    ]
  },
  {
    path: '/home',
    redirect: '/home',
    name: 'Escritorio',
    component: TheContainer, // montar componente
    children: [
      {
        path: '/',
        name: 'Inicio',
        component: Dashboard
      },      
    ]
  },
  {
    path: '/accesos',
    redirect: '/accesos/permisos',
    name: 'Accesos ',
    component: TheContainer,
    children: [
      {
        path: '/accesos/permisos',
        name: 'Permisos de usuario',
        component: Permission
      },
      {
        path: '/accesos/tipos',
        name: 'Roles de usuario',
        component: Roles
      },
      {
        path: '/accesos/usuarios',
        name: 'Usuarios',
        component: Users
      },
      // {
      //   path: '/accesos/permisos_asignados',
      //   name: 'Permisos Asignados',
      //   component: AssignedPermission
      // },
    ]
  },
  
  { path: '*', component: NotFound }
];

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
});

// router.beforeEach((to, from, next) => {
//   if(store.getters.get__token != null){
//     next()
//     return;
//   }else{
//     next('login')
//     return;
//   }
// });

// export default new Router({
//   mode: 'hash', // https://router.vuejs.org/api/#mode
//   linkActiveClass: 'open active',
//   scrollBehavior: () => ({ y: 0 }),
//   routes: routes
// })
export default router;