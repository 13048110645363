<template>
  <div>
    <router-view></router-view>
    <Loading v-show="loading"></Loading>
  </div>
</template>

<script>
import Loading from "./components/loading/Modal.vue";
import { mapActions } from "vuex";

import { bus } from "./main";

export default {
  name: "App",
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      url: "",
    };
  },
  created() {
    bus.$on("loading", (data) => {
      this.loading = data;
    });
    this.mtdgetcheck();
  },
  methods: {
    ...mapActions(["get", "post"]),

    mtdgetcheck: function () {
      this.get({
        url: this.$store.getters.get__url + "/Authentication/check",
        token: this.$store.getters.get__token,
      }).then((response) => {
        // console.log(this.$route.path);
        if (this.$route.path != "/consultas/clientes") {
          if (response.data.check) {
            this.$router.push("/home");
          } else {
            this.$router.push("/login");
          }
        }
        // console.log(response.data.check);
      });
    },
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
